import { Chart, registerables } from 'chart.js'
import ChartAnnotation from 'chartjs-plugin-annotation'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import ClassesModal from 'components/ClassesModal'
import CourseModal from 'components/CourseModal'
import InviteLearnerModal from 'components/InviteLearnerModal'
import Layout from 'components/Layout'
import SectionModal from 'components/SectionModal'
import {
  BORDER_RADIUS,
  BOX_SHADOW,
  Button,
  COLOR,
  GAP,
  Loader,
  MEDIA_QUERY,
  Paragraph,
  Select,
  Subheading,
  TYPOGRAPHY,
} from 'ui'
import {
  apiCall,
  capitalize,
  checkCourseEnrollment,
  checkCourseOwnership,
  darkenColour,
  getRandomColour,
  percentage,
  secondsToMinutes,
} from 'utils'
import { useAppContext } from 'context'

Chart.register(...registerables, ChartAnnotation)

const getChartDates = (stats) => {
  const studyDates = Object.keys(stats?.study.session || {})
  const quizDates = Object.keys(stats?.quiz.session || {})
  const startDate = new Date(studyDates[0])
  const endDate = Math.max(
    new Date(studyDates.at(-1)),
    quizDates.at(-1) ? new Date(quizDates.at(-1)) : -Infinity,
  )
  const dates = []
  while (startDate <= endDate) {
    const formattedDate = startDate.toISOString().slice(0, 10)
    // const formattedDate = `${startDate.getMonth() + 1}-${startDate.getDate()}`
    dates.push(formattedDate)
    startDate.setDate(startDate.getDate() + 1)
  }
  return dates
}

const getChartTimeOfDayColour = (timeOfDay) =>
  ({
    morning: '#fbe3aa',
    noon: '#fdb0bf',
    afternoon: '#9ccdf3',
    evening: '#fccca0',
    night: '#a7dcdc',
    late: '#c5acf9',
  })[timeOfDay]

// const chartVerticalLinePlugin = {
//   id: 'corsair',
//   defaults: {
//     width: 1,
//     color: '#FF4949',
//     dash: [3, 3],
//   },
//   afterInit: (chart, args, opts) => {
//     chart.corsair = {
//       x: 0,
//       y: 0,
//     }
//   },
//   afterEvent: (chart, args) => {
//     const { inChartArea } = args
//     const { x, y } = args.event

//     chart.corsair = { x, y, draw: inChartArea }
//     chart.draw()
//   },
//   beforeDatasetsDraw: (chart, args, opts) => {
//     const { ctx } = chart
//     const { top, bottom, left, right } = chart.chartArea
//     const { x, y, draw } = chart.corsair
//     if (!draw) return

//     ctx.save()

//     ctx.beginPath()
//     ctx.lineWidth = opts.width
//     ctx.strokeStyle = opts.color
//     ctx.setLineDash(opts.dash)
//     ctx.moveTo(x, bottom)
//     ctx.lineTo(x, top)
//     ctx.moveTo(left, y)
//     ctx.lineTo(right, y)
//     ctx.stroke()

//     ctx.restore()
//   },
// }

const Course = () => {
  const navigate = useNavigate()
  const {
    user,
    setError,
    openModal,
    closeModal,
    isLoading,
    setIsLoading,
    getCourseData,
    // getUserData,
    apiCache,
    setApiCache,
  } = useAppContext()
  const { courseId } = useParams()
  const [charts, setCharts] = useState(null)
  const [topicsChart, setTopicsChart] = useState(null)
  const topics = apiCache.topics?.filter((e) => e.courseId === courseId)
  const course = apiCache.course[courseId]
  const sections = course?.sections || []
  const isOwner = checkCourseOwnership({ user, courseId })
  const isEnrolled = checkCourseEnrollment({ user, courseId })
  const [selectdClassOption, setClassOption] = useState()
  const [classId, setClassId] = useState()
  const stats = apiCache.stats[courseId]?.[classId]
  const courseClass = course?.classes.find((e) => e.id === classId)
  const classOptions = course?.classes.map((c) => ({
    value: c.id,
    label: c.title,
  }))

  const resetCharts = () => {
    setCharts(null)
    setTopicsChart(null)
  }

  const handleClassChange = (e) => {
    setClassOption(e)
    setClassId(e.value)
    Object.values(charts).forEach((e) => {
      e.destroy()
    })
    setCharts(null)
  }

  const goToSection = (path, sectionNumber) => () => {
    navigate(`/${path}/${courseId}/${sectionNumber}`)
  }

  const saveCourse = async (courseData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putCourse',
      variables: { courseId },
      data: {
        ...courseData,
        capacity: Number(courseData.capacity),
      },
    }).catch((error) => {
      reportError({ source: 'saveCourse', error })
      setError('Failed to update course')
      return false
    })

    if (success) {
      getCourseData({ courseId, isEditMode: isOwner })
    }

    setIsLoading(false)
  }

  const saveSection = async (sectionData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'putSection',
      variables: {
        courseId,
        ...(sectionData.id ? { sectionId: sectionData.id } : {}),
      },
      data: sectionData,
    }).catch((error) => {
      reportError({ source: 'saveSection', error })
      setError('Failed to save section')
      return false
    })

    if (success) {
      await getCourseData({ courseId, isEditMode: isOwner })
    }

    setIsLoading(false)
    resetCharts()
  }

  const openCourseModal = () => {
    openModal({
      id: 'course',
      onSubmit: saveCourse,
      content: <CourseModal course={course} />,
    })
  }

  const openClassesModal = () => {
    openModal({
      id: 'classes',
      content: (
        <ClassesModal
          courseId={courseId}
          isEditMode={isOwner}
          resetCharts={resetCharts}
        />
      ),
    })
  }

  const inviteLearner = (variables) => {
    // TODO: api call to send invite
    // const example_variables = {
    //   courseId: '5f0eac16-ba4f-47c0-b504-beef778f7431',
    //   classId: 'd9734809-1285-485b-981b-934dae64598b',
    //   learnerEmails: ['dfgghj@rgdfgh.com', 'yfuyfyg@fgdchgc.net'],
    // }
  }

  const openInviteLearnerModal = () => {
    openModal({
      id: 'invite',
      onSubmit: inviteLearner,
      content: (
        <InviteLearnerModal course={course} classOptions={classOptions} />
      ),
    })
  }

  const openSectionModal = (targetsectionId) => () => {
    openModal({
      id: 'addSection',
      onSubmit: saveSection,
      content: (
        <SectionModal
          section={
            targetsectionId
              ? sections.find((c) => c.id === targetsectionId)
              : undefined
          }
          deleteSection={
            sections.length > 1 && targetsectionId ? deleteSection : undefined
          }
        />
      ),
    })
  }

  const deleteSection = (sectionId) => async (e) => {
    e?.preventDefault()

    const confirmed = confirm('Delete section?') // eslint-disable-line no-restricted-globals
    if (!confirmed) return

    setIsLoading(true)

    const success = await apiCall({
      query: 'deleteSection',
      variables: {
        courseId,
        sectionId,
      },
    }).catch((error) => {
      reportError({ source: 'deleteSection', error })
      setError('Failed to delete section')
      return false
    })

    if (success) {
      closeModal('addSection')
      await getCourseData({ courseId, sectionId, isEditMode: isOwner })
      resetCharts()
    }

    setIsLoading(false)
  }

  const getStats = async () => {
    const classAndSectionsStats = await apiCall({
      query: 'getStats',
      variables: {
        courseId,
        classId,
      },
    }).catch((error) => {
      reportError({ source: 'getStats', error })
      setError('Failed to get stats')
      return {
        class: null,
        sections: null,
      }
    })

    if (classAndSectionsStats) {
      let classStats = null
      let sectionsStats = null

      if (isEnrolled) {
        classStats = classAndSectionsStats.class
        sectionsStats = classAndSectionsStats.sections?.reduce((obj, e) => {
          obj[e.sectionId] = e
          return obj
        }, {})
      } else if (isOwner) {
        classStats = classAndSectionsStats.class
        sectionsStats = classAndSectionsStats.sections
      }

      setApiCache({
        ...apiCache,
        stats: {
          ...apiCache.stats,
          [courseId]: {
            ...(apiCache.stats[courseId] || {}),
            [classId]: {
              class: classStats,
              sections: sectionsStats,
            },
          },
        },
      })
    }
  }

  const getTopics = async () => {
    const fetchedTopics = await apiCall({
      query: 'getTopics',
    }).catch((error) => {
      reportError({ source: 'getTopics', error })
      setError('Failed to get topics')
      return null
    })

    if (fetchedTopics) {
      setApiCache({
        ...apiCache,
        topics: fetchedTopics.sort((a, b) => a.text.localeCompare(b.text)),
      })
    }
  }

  const renderLearnerTopicsChart = () => {
    const chartInstance = new Chart(document.getElementById('topics-chart'), {
      options: {
        responsive: true,
        scales: {
          x: {
            title: {
              display: true,
              color: COLOR.black,
              text: 'Topics Learned',
            },
          },
          y: {
            position: 'left',
            ticks: { stepSize: 1 },
            title: { display: true, color: COLOR.black, text: 'Count' },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: topics.map((e) => capitalize(e.text)),
        datasets: [
          {
            type: 'bar',
            data: topics.map((e) => e.count),
            backgroundColor: topics.map(() => getRandomColour()),
          },
        ],
      },
    })
    setTopicsChart(chartInstance)
  }

  const renderLearnerCharts = () => {
    const timesOfDay = [
      'morning',
      'noon',
      'afternoon',
      'evening',
      'night',
      'late',
    ]

    const sectionCharts = sections.reduce((obj, e) => {
      const sectionStats = stats.sections?.[e.id]
      const classSectionStats = stats.class?.sections?.[e.id]
      const dates = getChartDates(sectionStats)

      const durationPortion =
        percentage({
          value: sectionStats?.study.duration || 0,
          total: sectionStats
            ? sectionStats.study.duration +
              sectionStats.study.durationSupplement
            : 0,
        }) / 100
      const durationSupplementPortion = 1 - durationPortion

      obj[e.id] = new Chart(document.getElementById(`chart-${e.id}`), {
        // plugins: [chartVerticalLinePlugin],
        options: {
          responsive: true,
          scales: {
            x: {
              stacked: true,
              title: { display: true, color: COLOR.black, text: 'Date' },
            },
            minutes: {
              type: 'linear',
              position: 'left',
              stacked: true,
              ticks: { stepSize: 10 },
              title: {
                display: true,
                color: COLOR.black,
                text: 'Study Minutes',
              },
            },
            score: {
              type: 'linear',
              position: 'right',
              ticks: { stepSize: 20 },
              min: 0,
              max: 100,
              bounds: 'ticks',
              grid: { display: false },
              title: {
                display: true,
                color: COLOR.black,
                text: 'Quiz Score (%)',
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            annotation: {
              annotations: {
                ...(sectionStats?.quiz.timesQuizzed > 0
                  ? {
                      avgQuiz: {
                        type: 'line',
                        scaleID: 'score',
                        value: Math.round(sectionStats.quiz.avgScore),
                        backgroundColor: COLOR.darkGray,
                        borderColor: COLOR.darkGray,
                        borderWidth: 1,
                        borderDash: [20, 10],
                        label: {
                          display: true,
                          position: 'start',
                          content: `Average Quiz Score: ${Math.round(
                            sectionStats.quiz.avgScore,
                          )}%`,
                          font: { size: '8px' },
                          color: COLOR.white,
                          backgroundColor: COLOR.darkGray,
                          padding: 3,
                        },
                      },
                    }
                  : {}),
                ...(courseClass?.capacity > 1 &&
                classSectionStats?.quiz.avgScore > 0
                  ? {
                      classAvgQuiz: {
                        type: 'line',
                        scaleID: 'score',
                        value: Math.round(classSectionStats.quiz.avgScore),
                        backgroundColor: COLOR.red,
                        borderColor: COLOR.red,
                        borderWidth: 1,
                        borderDash: [20, 10],
                        label: {
                          display: true,
                          position: 'start',
                          content: `Class Average Quiz Score: ${Math.round(
                            classSectionStats.quiz.avgScore,
                          )}%`,
                          font: { size: '8px' },
                          color: COLOR.white,
                          backgroundColor: COLOR.red,
                          padding: 3,
                        },
                      },
                    }
                  : {}),
              },
            },
            // corsair: {
            //   color: 'black',
            // },
          },
        },
        data: {
          labels: dates,
          datasets: [
            ...timesOfDay
              .map((t) => [
                {
                  yAxisID: 'minutes',
                  type: 'bar',
                  order: 2,
                  label: `${capitalize(t)} Study Minutes`,
                  backgroundColor: getChartTimeOfDayColour(t),
                  data: dates.map((d) =>
                    secondsToMinutes(
                      (sectionStats?.study.session[d]?.timeOfDay[t] || 0) *
                        durationPortion,
                    ),
                  ),
                },
                {
                  yAxisID: 'minutes',
                  type: 'bar',
                  order: 2,
                  label: `${capitalize(t)} Supplement Study Minutes`,
                  backgroundColor: darkenColour(getChartTimeOfDayColour(t)),
                  data: dates.map((d) =>
                    secondsToMinutes(
                      (sectionStats?.study.session[d]?.timeOfDay[t] || 0) *
                        durationSupplementPortion,
                    ),
                  ),
                },
              ])
              .flat(1),
            {
              yAxisID: 'score',
              type: 'line',
              order: 1,
              label: 'Daily Average Quiz Score',
              backgroundColor: COLOR.black,
              borderColor: COLOR.black,
              pointRadius: 3,
              lineTension: 0.2,
              spanGaps: true,
              data: dates.map((d) => sectionStats?.quiz.session[d]?.avgScore),
            },
          ],
        },
      })

      return obj
    }, {})

    setCharts(sectionCharts)
  }

  const renderTeacherCharts = () => {
    const sectionCharts = sections.reduce((obj, e) => {
      const sectionStats = stats.sections?.[e.id]
      const dates = Object.keys(sectionStats?.quiz.session || {})
      const learnerIds = dates.reduce((array, date) => {
        const sessionLearnerIds = Object.keys(
          sectionStats?.quiz.session[date] || {},
        )
        sessionLearnerIds.forEach((learnerId) => {
          if (!array.includes(learnerId)) {
            array.push(learnerId)
          }
        })
        return array
      }, [])

      obj[e.id] = new Chart(document.getElementById(`chart-${e.id}`), {
        options: {
          responsive: true,
          scales: {
            x: {
              title: { display: true, color: COLOR.black, text: 'Quiz Date' },
            },
            score: {
              type: 'linear',
              position: 'left',
              ticks: { stepSize: 20 },
              min: 0,
              max: 100,
              bounds: 'ticks',
              grid: { display: false },
              title: {
                display: true,
                color: COLOR.black,
                text: 'Quiz Score (%)',
              },
            },
          },
          plugins: {
            // legend: {
            //   display: false,
            // },
            annotation: {
              annotations: {
                ...(sectionStats?.quiz.timesQuizzed > 0
                  ? {
                      avgQuiz: {
                        type: 'line',
                        scaleID: 'score',
                        value: Math.round(sectionStats.quiz.avgScore),
                        backgroundColor: COLOR.red,
                        borderColor: COLOR.red,
                        borderWidth: 1,
                        borderDash: [20, 10],
                        label: {
                          display: true,
                          position: 'start',
                          content: `Class Average Quiz Score: ${Math.round(
                            sectionStats.quiz.avgScore,
                          )}%`,
                          font: { size: '8px' },
                          color: COLOR.white,
                          backgroundColor: COLOR.red,
                          padding: 3,
                        },
                      },
                    }
                  : {}),
              },
            },
          },
        },
        data: {
          labels: dates,
          datasets: learnerIds.map((learnerId) => ({
            yAxisID: 'score',
            type: 'line',
            label: learnerId,
            // label: `${learnerId}'s Daily Average Quiz Score`,
            pointRadius: 3,
            lineTension: 0.2,
            spanGaps: true,
            data: dates.map(
              (d) => sectionStats?.quiz.session[d]?.[learnerId]?.avgScore,
            ),
          })),
        },
      })

      return obj
    }, {})

    setCharts(sectionCharts)
  }

  const renderCharts = () => {
    if (isEnrolled) {
      renderLearnerCharts()
    } else if (isOwner) {
      renderTeacherCharts()
    }
  }

  useEffect(() => {
    if (user && course && courseId && classId === undefined) {
      setClassId(
        isEnrolled
          ? user.enrollIds[courseId]
          : isOwner
            ? course.classes[0]?.id
            : null,
      )
    }
  }, [user, course, courseId, classId, isOwner, isEnrolled])

  useEffect(() => {
    if (user && !course && courseId) {
      getCourseData({ courseId, isEditMode: isOwner })
    }
  }, [user, course, courseId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && course && classId && !stats && (isOwner || isEnrolled)) {
      getStats()
    }
  }, [user, course, classId, stats, isOwner, isEnrolled]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && course && classId && sections && stats && !charts) {
      renderCharts()
    }
  }, [user, course, classId, sections, stats, charts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEnrolled && charts && !topicsChart) {
      if (topics) {
        renderLearnerTopicsChart()
      } else {
        getTopics()
      }
    }
  }, [topics, topicsChart, charts, isEnrolled]) // eslint-disable-line react-hooks/exhaustive-deps

  const menu = isLoading
    ? []
    : isOwner
      ? [
          <Button
            key="inviteButton"
            onClick={openInviteLearnerModal}
            disabled={isLoading}
          >
            Invite
          </Button>,
          <Button
            key="addSectionButton"
            onClick={openSectionModal()}
            disabled={isLoading}
          >
            Add Section
          </Button>,
          <Button
            key="showClassesButton"
            onClick={openClassesModal}
            disabled={isLoading}
          >
            Classes
          </Button>,
          <Button
            key="courseSettingsButton"
            onClick={openCourseModal}
            disabled={isLoading}
          >
            Settings
          </Button>,
        ]
      : []
  // : isEnrolled
  //   ? []
  //   : [
  //       <Button
  //         key="courseEnrollButton"
  //         onClick={openEnrollInCourseModal}
  //         disabled={isLoading}
  //       >
  //         Enroll
  //       </Button>,
  //     ]

  return (
    <Layout page={{ title: course?.title || 'Course', menu }}>
      {isLoading ? (
        <Loader big />
      ) : (
        <Wrapper>
          {!!course?.description && (
            <CourseDec>{course?.description}</CourseDec>
          )}
          {!!topics?.length && (
            <TopicsChart>
              <canvas id="topics-chart" />
            </TopicsChart>
          )}
          {isOwner && !!classOptions?.length && (
            <Paragraph style={{ width: '250px' }}>
              <Select
                label="Chart data"
                placeholder="Select class"
                options={classOptions}
                isSearchable={true}
                defaultValue={classOptions[0]}
                value={selectdClassOption}
                onChange={handleClassChange}
              />
            </Paragraph>
          )}
          <Sections>
            {sections.map((e) => {
              const sectionData = stats?.sections?.[e.id]
              return (
                <li key={e.id}>
                  <div>
                    <Subheading>
                      {e.number}. {e.title}
                    </Subheading>
                    {!!e.description && <Paragraph>{e.description}</Paragraph>}
                    {isEnrolled && (
                      <Paragraph>
                        <i>
                          Total study sessions:{' '}
                          {sectionData?.study.timesStudied || 0}
                          <br />
                          Total study duration:{' '}
                          {secondsToMinutes(
                            sectionData?.study.duration || 0,
                          )}{' '}
                          minutes
                          <br />
                          Total supplement study duration:{' '}
                          {secondsToMinutes(
                            sectionData?.study.durationSupplement || 0,
                          )}{' '}
                          minutes
                          <br />
                          Average study duration per session:{' '}
                          {secondsToMinutes(
                            ((sectionData?.study.duration || 0) +
                              (sectionData?.study.durationSupplement || 0)) /
                              (sectionData?.study.timesStudied || 1),
                          )}{' '}
                          minutes
                          <br />
                          <br />
                          Total number of quizzes:{' '}
                          {sectionData?.quiz.timesQuizzed || 0}
                          <br />
                          Average quiz score:{' '}
                          {Math.round(sectionData?.quiz.avgScore || 0)}
                          %
                          <br />
                          Difference with class average quiz score:{' '}
                          {Math.round(
                            (sectionData?.quiz.avgScore || 0) -
                              (stats?.class.sections[e.id]?.quiz.avgScore || 0),
                          )}
                          %
                        </i>
                      </Paragraph>
                    )}
                  </div>
                  {(isOwner || isEnrolled) && (
                    <div>
                      <Buttons>
                        {isOwner && (
                          <>
                            <Button onClick={openSectionModal(e.id)}>
                              Settings
                            </Button>
                            {/* {sections.length > 1 && (
                          <Button onClick={deleteSection(e.id)}>Delete</Button>
                        )} */}
                            <Button
                              primary
                              onClick={goToSection('edit', e.number)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                        {isEnrolled && (
                          <>
                            {!!sectionData?.study.timesStudied && (
                              <Button onClick={goToSection('quiz', e.number)}>
                                Take Quiz
                              </Button>
                            )}
                            <Button
                              primary
                              onClick={goToSection('study', e.number)}
                            >
                              Study
                            </Button>
                          </>
                        )}
                      </Buttons>
                      <canvas
                        id={`chart-${e.id}`}
                        // aria-label={chartTitle}
                        role="img"
                      />
                    </div>
                  )}
                </li>
              )
            })}
          </Sections>
        </Wrapper>
      )}
    </Layout>
  )
}

const Wrapper = styled.div`
  padding: ${GAP.sm};
`

const CourseDec = styled(Paragraph)`
  margin-bottom: ${GAP.lg};
  font-size: ${TYPOGRAPHY.size.sm};
`

const TopicsChart = styled.div`
  margin: ${GAP.md} auto;
  width: 100%;
  max-width: 600px;
`

const Sections = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${GAP.md};

  li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${GAP.md};
    /* border: 1px solid ${COLOR.gray}; */
    border-radius: ${BORDER_RADIUS};
    padding: ${GAP.md};
    /* box-shadow: ${BOX_SHADOW}; */
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    > div {
      width: 100%;
    }

    ${MEDIA_QUERY.md} {
      flex-direction: row;
      justify-content: space-between;
      gap: ${GAP.xl};

      > div {
        &:first-child {
          width: 35%;
        }
        &:last-child {
          width: 65%;
        }
      }
    }
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${GAP.xs};
  margin-bottom: ${GAP.md};

  button {
    white-space: nowrap;
  }
`

export default Course
