// import { Redirect } from 'App'
import { useEffect, useState } from 'react'
import InviteUserModal from 'components/InviteUserModal'
// import { useParams } from 'react-router-dom'
import Layout from 'components/Layout'
import {
  Box,
  Button,
  Loader,
  Paragraph,
  Table, // GAP,
  // Heading,
  // Subheading,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'

// import { MAIN_ROUTE, SIGNIN_REDIRECT_STORAGE, SIGNIN_URL } from 'config'

// Dummy data for testing the UI
const dummyPeople = [
  {
    id: '1',
    nameFull: 'John School Admin',
    email: 'john@school.com',
    role: 'school_admin',
    canInvite: true,
    canCreateCourse: true,
    canEditCourse: true,
    canEditCourseMaterial: true,
    canDeleteCourse: true,
    canCreateClass: true,
    canEditClass: true,
    canDeleteClass: true,
    canAssignClass: true,
    canAccessClassAnalytics: true,
    canAccessLearnerAnalytics: true,
  },
  {
    id: '2',
    nameFull: 'Sarah School Admin',
    email: 'sarah@school.com',
    role: 'school_admin',
    canInvite: true,
    canCreateCourse: true,
    canEditCourse: true,
    canEditCourseMaterial: false,
    canDeleteCourse: true,
    canCreateClass: true,
    canEditClass: true,
    canDeleteClass: true,
    canAssignClass: true,
    canAccessClassAnalytics: false,
    canAccessLearnerAnalytics: false,
  },
  {
    id: '3',
    nameFull: 'Mike Teacher',
    email: 'mike@teacher.com',
    role: 'teacher',
    canInvite: false,
    canCreateCourse: false,
    canEditCourse: false,
    canEditCourseMaterial: true,
    canDeleteCourse: false,
    canCreateClass: false,
    canEditClass: false,
    canDeleteClass: false,
    canAssignClass: false,
    canAccessClassAnalytics: true,
    canAccessLearnerAnalytics: true,
  },
  {
    id: '4',
    nameFull: 'Lisa Parent',
    email: 'lisa@parent.com',
    role: 'parent',
    canInvite: false,
    canCreateCourse: false,
    canEditCourse: false,
    canEditCourseMaterial: false,
    canDeleteCourse: false,
    canCreateClass: false,
    canEditClass: false,
    canDeleteClass: false,
    canAssignClass: false,
    canAccessClassAnalytics: true,
    canAccessLearnerAnalytics: true,
  },
  {
    id: '5',
    nameFull: 'Tom Student',
    email: 'tom@student.com',
    role: 'learner',
    canInvite: false,
    canCreateCourse: false,
    canEditCourse: false,
    canEditCourseMaterial: false,
    canDeleteCourse: false,
    canCreateClass: false,
    canEditClass: false,
    canDeleteClass: false,
    canAssignClass: false,
    canAccessClassAnalytics: true,
    canAccessLearnerAnalytics: true,
  },
]

const People = () => {
  const { user, isLoading, setIsLoading, setError, openModal } = useAppContext()
  const [people, setPeople] = useState(dummyPeople)

  useEffect(() => {
    const getPeople = async () => {
      setIsLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 500))
      setIsLoading(false)
    }
    getPeople()
  }, [setIsLoading])

  const inviteUser = async (formData) => {
    // TODO: Implement the actual invite functionality
    console.log('Invite form data:', formData)
  }

  const openInviteUserModal = () => {
    openModal({
      id: 'inviteUser',
      onSubmit: inviteUser,
      content: <InviteUserModal user={user} />,
    })
  }

  const handleDelete = async (userId) => {
    const confirm = window.confirm(
      'Are you sure you want to remove this user?\n\nThey will lose access to some functionality and material.\nSome of their data might also get deleted.',
    )
    if (!confirm) return

    // For now, just remove the user from the client-side state
    setPeople(people.filter((person) => person.id !== userId))

    // TODO: In the future, this will make an API call to remove the relationship
    // await apiCall({
    //   query: 'removeUserRelation',
    //   variables: { userId },
    // })
  }

  const handlePermissionChange = async (userId, permission, value) => {
    // Optimistic update
    setPeople(
      people.map((person) =>
        person.id === userId ? { ...person, [permission]: value } : person,
      ),
    )

    const success = await apiCall({
      query: 'putPermissions',
      variables: {
        userId,
      },
      data: {
        [permission]: value,
      },
    }).catch((error) => {
      reportError({ error })
      setError('Failed to update permissions')
      return false
    })

    if (!success) {
      // Revert the optimistic update
      setPeople(
        people.map((person) =>
          person.id === userId ? { ...person, [permission]: !value } : person,
        ),
      )
    }
  }

  const columns = [
    { header: 'Name', accessor: 'nameFull' },
    { header: 'Email', accessor: 'email' },
    { header: 'Role', accessor: 'role' },
    { header: 'Invite', accessor: 'canInvite', type: 'checkbox' },
    {
      header: 'Create Course',
      accessor: 'canCreateCourse',
      type: 'checkbox',
    },
    { header: 'Edit Course', accessor: 'canEditCourse', type: 'checkbox' },
    {
      header: 'Edit Course Material',
      accessor: 'canEditCourseMaterial',
      type: 'checkbox',
    },
    {
      header: 'Delete Course',
      accessor: 'canDeleteCourse',
      type: 'checkbox',
    },
    {
      header: 'Create Class',
      accessor: 'canCreateClass',
      type: 'checkbox',
    },
    { header: 'Edit Class', accessor: 'canEditClass', type: 'checkbox' },
    {
      header: 'Delete Class',
      accessor: 'canDeleteClass',
      type: 'checkbox',
    },
    {
      header: 'Assign Class',
      accessor: 'canAssignClass',
      type: 'checkbox',
    },
    {
      header: 'Class Analytics',
      accessor: 'canAccessClassAnalytics',
      type: 'checkbox',
    },
    {
      header: 'Student Analytics',
      accessor: 'canAccessLearnerAnalytics',
      type: 'checkbox',
    },
    {
      header: '',
      accessor: 'canDelete',
      type: 'action',
      render: (row) => (
        // row.canDelete ? (
        <Button
          variant="danger"
          size="small"
          onClick={() => handleDelete(row.id)}
          aria-label={`Remove ${row.nameFull}`}
          style={{
            padding: '5px',
            fontSize: '10px',
            minHeight: 'unset',
          }}
        >
          Remove
        </Button>
      ),
      // ) : null
    },
  ]

  const menu =
    // user?.isSubscriber && user?.permission.canInvite
    true
      ? [
          <Button
            key="inviteButton"
            onClick={openInviteUserModal}
            aria-label="Invite people"
          >
            Invite
          </Button>,
        ]
      : []

  return (
    <Layout page={{ title: 'People', menu }} maxWidth={false}>
      {isLoading ? (
        <Loader big />
      ) : (
        <Box>
          <Paragraph>
            Manage the people who can use the service through your account.
          </Paragraph>
          <Table
            data={people}
            columns={columns}
            onCheckboxChange={handlePermissionChange}
            freezeFirstColumn
          />
        </Box>
      )}
    </Layout>
  )
}

export default People
