import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  BORDER_RADIUS,
  Button,
  COLOR,
  Checkbox,
  Flex,
  GAP,
  Grid,
  Input,
  MAX_WIDTH,
  MEDIA_QUERY,
  Paragraph,
  Select,
  Subheading,
} from 'ui'
import { apiCall } from 'utils'
import { useAppContext } from 'context'
import {
  DEFAULT_TIME_ZONE,
  PROVIDER_STORAGE,
  SITE_TITLE,
  TIME_ZONES,
} from 'config'

const STEP = {
  type: 'type',
  profile: 'profile',
  join: 'join',
}

const Welcome = () => {
  const navigate = useNavigate()
  const { getUserData, user, isLoading, setIsLoading, setError } =
    useAppContext()
  const providerKey = localStorage.getItem(PROVIDER_STORAGE)
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm()
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm()
  const [onboardData, setOnboardData] = useState({
    type: user?.type,
    timezone: user?.timezone,
    language: user?.language,
    subscription: user?.subscription,
    plan: user?.plan,
  })
  const [step, setStep] = useState()
  const isSchool = onboardData.type === 'school'
  const isTeacher = onboardData.type === 'teacher'
  const isParent = onboardData.type === 'parent'
  const isLearner = onboardData.type === 'learner'
  const canJoin = isTeacher || isParent || isLearner
  const canSubscribe = isSchool
  const timezoneOptions = useMemo(
    () => TIME_ZONES.map((e) => ({ value: e, label: e })),
    [],
  )
  const languageOptions = useMemo(
    () => [
      { value: 'en', label: 'English' },
      // { value: 'fr', label: 'French' },
    ],
    [],
  )
  const currentTimezone = timezoneOptions.find(
    (e) => e.value === (user?.timezone || DEFAULT_TIME_ZONE),
  )
  const currentLanguage = languageOptions.find(
    (e) => e.value === (user?.language || 'en'),
  )

  useEffect(() => {
    if (providerKey) {
      setStep(STEP.profile)
    } else if (!onboardData.type) {
      setStep(STEP.type)
    } else if (!onboardData.terms) {
      setStep(STEP.profile)
    } else if (!onboardData.subscription?.isActive || !onboardData.plan) {
      setStep(STEP.join)
    }
  }, [onboardData, providerKey])

  const onTypeClick = (type) => () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm(`Set type to "${type}"? This can't change later.`)
    if (!confirmed) return
    setOnboardData((prev) => ({ ...prev, type }))
  }

  const onboard = async (formData) => {
    const formattedOnboardData = {
      ...onboardData,
      fullname: formData.fullname,
      schoolname: formData.schoolname,
      terms: formData.terms,
      language: formData.language.value,
      timezone: formData.timezone.value,
    }

    setIsLoading(true)

    const success = await apiCall({
      query: 'onboardUser',
      data: formattedOnboardData,
    }).catch((error) => {
      reportError({ source: 'onboardUser', error })
      setError('Failed to onboard user')
      return false
    })

    if (success) {
      // getUserData()
      setOnboardData((prev) => ({ ...prev, ...formattedOnboardData }))
      if (providerKey) onJoin({ providerKey })
    }

    if (!success || !providerKey) {
      setIsLoading(false)
    }
  }

  const onJoin = async (formData) => {
    setIsLoading(true)

    const success = await apiCall({
      query: 'acceptInvite',
      data: {
        providerKey: formData.providerKey,
        isAccepted: true,
      },
    }).catch((error) => {
      reportError({ source: 'acceptInvite', error })
      setError('Failed to accept invite')
      return false
    })

    if (success) {
      // getUserData()
      // localStorage.removeItem(PROVIDER_STORAGE)
      // TODO: redirect to `/home`
    }

    setIsLoading(false)
  }

  const onSubscribe = () => {
    // formData.paymentMethod = formData.paymentMethod?.value
    // TODO: redirect to `/subscribe`
    navigate('/home')
  }

  return (
    <>
      <Wrapper>
        <Header>
          <Inner>
            <Logo>
              <span>Edu</span>Gauger
            </Logo>
          </Inner>
        </Header>
        <Wizard $step={step}>
          <Content id={STEP.type}>
            <Inner>
              <Subheading>Welcome! Choose account type to continue:</Subheading>
              <Boxes>
                <Button onClick={onTypeClick('school')}>
                  <Paragraph>
                    <b>School</b>
                  </Paragraph>
                  <Paragraph>
                    Provide data-driven learning. Invite teachers, learners, and
                    their parents.
                  </Paragraph>
                </Button>
                <Button onClick={onTypeClick('teacher')}>
                  <Paragraph>
                    <b>Teacher</b>
                  </Paragraph>
                  <Paragraph>
                    Join a school, or invite parents and learners for tutoring.
                  </Paragraph>
                </Button>
                <Button onClick={onTypeClick('parent')}>
                  <Paragraph>
                    <b>Parent</b>
                  </Paragraph>
                  <Paragraph>
                    Join a school or tutor, or invite your children for
                    homeschooling.
                  </Paragraph>
                </Button>
                <Button onClick={onTypeClick('learner')}>
                  <Paragraph>
                    <b>Learner</b>
                  </Paragraph>
                  <Paragraph>
                    Join a school, or a tutor, or a parent to start personalized
                    learning.
                  </Paragraph>
                </Button>
              </Boxes>
            </Inner>
          </Content>
          <Content id={STEP.profile}>
            <Inner>
              <Subheading>Complete profile:</Subheading>
              <form onSubmit={handleSubmit(onboard)}>
                <Boxes>
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input label="Email*" readOnly={true} {...field} />
                    )}
                    defaultValue={user?.email}
                    disabled={isLoading}
                  />
                  <Controller
                    control={control}
                    name="fullname"
                    render={({ field }) => (
                      <Input
                        label="Full name*"
                        error={errors.fullname}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Name is required' }}
                    defaultValue={user?.name?.full}
                    disabled={isLoading}
                  />
                  {onboardData.type === 'school' && (
                    <Controller
                      control={control}
                      name="schoolname"
                      render={({ field }) => (
                        <Input
                          label="School name*"
                          error={errors.schoolname}
                          {...field}
                        />
                      )}
                      rules={{ required: 'School name is required' }}
                      defaultValue={user?.name?.school}
                      disabled={isLoading}
                    />
                  )}
                  <Controller
                    control={control}
                    name="language"
                    render={({ field }) => (
                      <Select
                        label="Language*"
                        options={languageOptions}
                        error={errors.language}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Language is required' }}
                    defaultValue={currentLanguage}
                    disabled={isLoading}
                  />
                  <Controller
                    control={control}
                    name="timezone"
                    render={({ field }) => (
                      <Select
                        label="Timezone*"
                        options={timezoneOptions}
                        isSearchable={true}
                        error={errors.timezone}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Timezone is required' }}
                    defaultValue={currentTimezone}
                    disabled={isLoading}
                  />
                </Boxes>
                <Flex justifyContent="space-between" margin={`${GAP.xl} 0 0 0`}>
                  <Controller
                    control={control}
                    name="terms"
                    render={({ field }) => (
                      <Checkbox
                        label="Accept terms of service*"
                        error={errors.terms}
                        {...field}
                      />
                    )}
                    rules={{ required: 'Terms is required' }}
                    defaultValue={user?.terms}
                    disabled={isLoading}
                  />
                  <Button
                    type="submit"
                    primary="true"
                    disabled={!isDirty || !isValid || isLoading}
                  >
                    Next
                  </Button>
                </Flex>
              </form>
            </Inner>
          </Content>
          <Content id={STEP.join}>
            <Inner>
              <Subheading>
                {isSchool
                  ? 'Become a provider:'
                  : isLearner
                    ? 'Join a provider:'
                    : `Join a provider or become one:`}
              </Subheading>
              <em>
                Provider is the person or entity that pays for the service and
                has administrative access.
              </em>
              <Boxes>
                {canJoin && (
                  <form onSubmit={handleSubmit2(onJoin)} className="box">
                    <Controller
                      control={control2}
                      name="providerKey"
                      render={({ field }) => (
                        <Input
                          label="Join provider by ID"
                          error={errors2.providerKey}
                          readOnly={!!providerKey}
                          {...field}
                        />
                      )}
                      rules={{ required: 'Provider ID is required' }}
                      defaultValue={providerKey}
                      disabled={isLoading}
                    />
                    <Flex justifyContent="flex-end">
                      <Button type="submit" primary="true" disabled={isLoading}>
                        Join
                      </Button>
                    </Flex>
                  </form>
                )}
                {canSubscribe && (
                  <Flex justifyContent="flex-end" gap={GAP.md} className="box">
                    <label>Become a provider</label>
                    <Button
                      primary="true"
                      big="true"
                      onClick={onSubscribe}
                      disabled={isLoading}
                    >
                      Subscribe
                    </Button>
                  </Flex>
                )}
              </Boxes>
            </Inner>
          </Content>
        </Wizard>
        <Footer>
          &copy; {new Date().getFullYear()} {SITE_TITLE}
        </Footer>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  background-color: ${COLOR.white};
`

const Inner = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: ${MAX_WIDTH};
`

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0 ${GAP.sm};
  width: 100%;
  z-index: 1;
`

const Logo = styled.div`
  display: inline-block;
  margin: 0;
  padding: ${GAP.sm};
  font-size: 20px;
  font-weight: 400;
  color: ${COLOR.black};
  background-color: ${COLOR.white};
  border-radius: ${BORDER_RADIUS};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 2;
  transition: all 0.5s;

  span {
    font-weight: 600;
    color: ${COLOR.blue};
  }
`

const Wizard = styled.div`
  /* position: relative; */
  min-height: 100vh;
  display: flex;
  /* overflow-x: auto; */
  /* scroll-snap-type: x mandatory; */

  & > div {
    /* scroll-snap-align: center; */
    min-width: 100%;
    min-height: 100%;
    /* position: absolute; */
    display: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
  }

  #${({ $step }) => $step} {
    display: block;
    opacity: 1;
    pointer-events: all;
  }
`

const Content = styled.div`
  margin: 0;
  padding: ${GAP.xxl} ${GAP.md};
  line-height: 2;
  /* background-image: url('/assets/image/nodes.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain; */

  &:nth-child(odd) {
    background-color: ${COLOR.blue};
    color: ${COLOR.white};

    h2 {
      color: ${COLOR.white};
    }

    p {
      color: ${COLOR.white} !important;
    }

    label span {
      color: ${COLOR.white};
    }

    div[role='option'] {
      color: ${COLOR.black};
    }

    form button:hover,
    button:not(:has(p)):hover {
      border-color: ${COLOR.black};
    }
  }

  h2 {
    margin: 0 0 ${GAP.lg} 0 !important;
    color: ${COLOR.blue};
    font-size: 26px;
    letter-spacing: 1px;

    &.shadow {
      text-shadow: 2px 2px ${COLOR.black};
    }

    &.center {
      text-align: center;
    }
  }

  p {
    color: ${COLOR.black};
    text-align: left;
    font-size: 16px;

    ${MEDIA_QUERY.md} {
      font-size: 20px;
    }
  }

  em {
    display: block;
    font-size: 16px;
    margin-top: -${GAP.lg};
    margin-bottom: ${GAP.lg};
  }

  label {
    margin-bottom: 0;
  }

  a {
    color: ${COLOR.black};
  }
`

const Boxes = styled(Grid)`
  flex-wrap: wrap;
  justify-content: center;
  /* text-align: center; */
  gap: ${GAP.lg};
  grid-template-columns: 1fr;

  ${MEDIA_QUERY.md} {
    grid-template-columns: 1fr 1fr;
  }

  img {
    width: 70%;
    max-width: 250px;
  }

  & > button,
  .box {
    border: 2px solid ${COLOR.black};
    border-radius: ${BORDER_RADIUS};
    background-color: transparent;
    text-align: left;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${GAP.sm};
    padding: ${GAP.md} ${GAP.sm};

    &:hover,
    &:focus {
      border-color: ${COLOR.white};
    }

    b {
      background-color: ${COLOR.black};
      color: ${COLOR.green};
      padding: ${GAP.xs};
      border-radius: ${BORDER_RADIUS};
      letter-spacing: 1px;
    }

    label {
      width: 90%;
      text-align: center;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: ${COLOR.black};
      text-align: center;
      max-width: 65%;
      line-height: normal;
      font-size: 16px;

      &.title {
        display: inline-block;
        border-radius: ${BORDER_RADIUS};
        background-color: ${COLOR.black};
        color: ${COLOR.green} !important;
        margin: ${GAP.sm} 0;
        padding: 0 15px;
        width: auto;
        max-width: unset;
        line-height: inherit;
        font-size: 18px;
        text-align: center;
      }
    }
  }
`

const Footer = styled.div`
  margin: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: ${COLOR.blue};
  color: ${COLOR.white};
  font-size: 12px;
`

export default Welcome
