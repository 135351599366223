import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Button, COLOR, Flex, GAP, Heading, Input, Select, Table } from 'ui'
import { isEmail } from 'utils'

const formatEmailsValue = (input) =>
  input.split(',').map((e) => e.trim().toLowerCase())

// Dummy data for testing
const dummyPendingInvites = [
  {
    id: '1',
    email: 'john@example.com',
    type: 'teacher',
    date: '2024-03-20',
  },
  {
    id: '2',
    email: 'sarah@example.com',
    type: 'learner',
    date: '2024-03-19',
  },
]

const InviteUserModal = ({ submitModal, closeModal, user }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const isSchoolAdmin = user?.isSubscriber && user?.role === 'school_admin'
  const isTeacher = user?.isSubscriber && user?.role === 'teacher'
  const isParent = user?.isSubscriber && user?.role === 'parent'
  const isLearner = user?.isSubscriber && user?.role === 'learner'

  const inviteTypes = [
    ...(isSchoolAdmin
      ? [{ value: 'school_admin', label: 'School Admin' }]
      : []),
    ...(isSchoolAdmin || isParent || isLearner
      ? [{ value: 'teacher', label: 'Teacher' }]
      : []),
    ...(isSchoolAdmin || isTeacher
      ? [{ value: 'parent', label: 'Parent' }]
      : []),
    ...(isSchoolAdmin || isTeacher || isParent
      ? [{ value: 'learner', label: 'Learner' }]
      : []),
  ]

  const handleCancelInvite = (inviteId) => {
    // TODO: Implement cancel invite functionality
    console.log('Cancel invite:', inviteId)
  }

  const pendingInvitesColumns = [
    { header: 'Email', accessor: 'email' },
    { header: 'Type', accessor: 'type' },
    { header: 'Date', accessor: 'date' },
    {
      header: '',
      accessor: 'id',
      type: 'action',
      render: (row) => (
        <Button
          variant="danger"
          size="small"
          onClick={() => handleCancelInvite(row.id)}
          aria-label={`Cancel invite for ${row.email}`}
          style={{
            padding: '5px',
            fontSize: '10px',
            minHeight: 'unset',
          }}
        >
          Cancel
        </Button>
      ),
    },
  ]

  const onSubmit = (formData) => {
    formData.type = formData.type.value
    formData.userEmails = formatEmailsValue(formData.userEmails)
    submitModal(formData)
  }

  return (
    <Content>
      <Heading secondary>Invite people</Heading>
      {/* New Invite Form */}
      <Section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <Select
                label="Type*"
                options={inviteTypes}
                error={errors.type}
                {...field}
              />
            )}
            rules={{ required: 'Type is required' }}
            defaultValue={inviteTypes[0]}
          />
          <Controller
            control={control}
            name="userEmails"
            render={({ field }) => (
              <Input
                isMultiLine={true}
                label="Email* (separate with comma if more than one)"
                error={errors.userEmails}
                {...field}
              />
            )}
            rules={{
              required: 'Valid user email is required',
              validate: (value) => {
                return (
                  formatEmailsValue(value).every((v) => isEmail(v)) ||
                  'Valid user email is required'
                )
              },
            }}
            defaultValue=""
          />
          <Flex justifyContent="flex-end" margin={`${GAP.lg} 0 0 0`}>
            <Button onClick={closeModal} margin={`0 ${GAP.sm} 0 0 `}>
              Cancel
            </Button>
            <Button type="submit" primary>
              Invite
            </Button>
          </Flex>
        </form>
      </Section>

      <Divider />

      {/* Pending Invites Section */}
      <Section>
        <Subheading>Pending Invites</Subheading>
        {dummyPendingInvites.length > 0 ? (
          <Table
            data={dummyPendingInvites}
            columns={pendingInvitesColumns}
            freezeFirstColumn
          />
        ) : (
          <NoInvites>No pending invites</NoInvites>
        )}
      </Section>
    </Content>
  )
}

const Content = styled.div`
  position: relative;
  margin: 0;
  padding: ${GAP.md};
`

const Section = styled.div`
  margin-bottom: ${GAP.lg};
`

const Subheading = styled.h3`
  margin: 0 0 ${GAP.md} 0;
  font-size: 1rem;
  font-weight: 600;
  color: #666;
`

const Divider = styled.div`
  border: none;
  height: 1px;
  margin: ${GAP.lg} 0;
  background-color: ${COLOR.blue};
`

const NoInvites = styled.div`
  text-align: center;
  color: #666;
  padding: ${GAP.md};
  background: #f9f9f9;
  border-radius: 4px;
`

export default InviteUserModal
