import styled from 'styled-components'
import Checkbox from './Checkbox'

const TableWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: 10px;
  border: 1px solid #eee;
  overflow: hidden;
`

const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;

  /* Scope hover effects to this table only */
  &.hovering {
    tbody tr:hover td {
      background-color: #f9f9f9;
    }

    td.hover,
    th.hover {
      background-color: #f9f9f9;
    }
  }
`

const TableHeader = styled.th`
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid #eee;
  font-weight: 600;
  background: white;
  transition: background-color 0.2s ease;

  ${({ isFirstColumn, freezeFirstColumn }) =>
    isFirstColumn && freezeFirstColumn
      ? `
    position: sticky;
    left: 0;
    z-index: 2;
    border-right: 2px solid #eee;
  `
      : ''}
`

const TableCell = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #eee;
  background: white;
  transition: background-color 0.2s ease;

  ${({ isFirstColumn, freezeFirstColumn }) =>
    isFirstColumn && freezeFirstColumn
      ? `
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 2px solid #eee;
    font-weight: 600;
  `
      : ''}

  ${({ isCheckbox, isAction }) =>
    (isCheckbox || isAction) &&
    `
    text-align: center;
    
    /* Center the checkbox wrapper div or button */
    & > div, & > button {
      margin: 0 auto;
    }
  `}

  &:last-child {
    border-right: none;
  }
`

const TableRow = styled.tr`
  &:last-child td {
    border-bottom: none;
  }
`

const Table = ({
  data = [],
  columns = [],
  onCheckboxChange,
  freezeFirstColumn = false,
}) => {
  const tableId = `table-${Math.random()}`

  const handleMouseEnter = (columnIndex) => {
    const table = document.getElementById(tableId)
    if (table) {
      table.classList.add('hovering')
      const cells = table.querySelectorAll(
        `td:nth-child(${columnIndex + 1}), th:nth-child(${columnIndex + 1})`,
      )
      cells.forEach((cell) => cell.classList.add('hover'))
    }
  }

  const handleMouseLeave = (columnIndex) => {
    const table = document.getElementById(tableId)
    if (table) {
      table.classList.remove('hovering')
      const cells = table.querySelectorAll(
        `td:nth-child(${columnIndex + 1}), th:nth-child(${columnIndex + 1})`,
      )
      cells.forEach((cell) => cell.classList.remove('hover'))
    }
  }

  return (
    <TableWrapper>
      <ScrollContainer>
        <StyledTable id={tableId}>
          <thead>
            <TableRow>
              {columns.map((column, index) => (
                <TableHeader
                  key={column.accessor}
                  isFirstColumn={index === 0}
                  freezeFirstColumn={freezeFirstColumn}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                >
                  {column.header}
                </TableHeader>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {data.map((row) => (
              <TableRow key={row.id}>
                {columns.map((column, index) => (
                  <TableCell
                    key={`${row.id}-${column.accessor}`}
                    isFirstColumn={index === 0}
                    freezeFirstColumn={freezeFirstColumn}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    isCheckbox={column.type === 'checkbox'}
                    isAction={column.type === 'action'}
                  >
                    {column.type === 'checkbox' ? (
                      <Checkbox
                        checked={row[column.accessor]}
                        onChange={(e) =>
                          onCheckboxChange(
                            row.id,
                            column.accessor,
                            e.target.checked,
                          )
                        }
                      />
                    ) : column.type === 'action' ? (
                      column.render(row)
                    ) : (
                      row[column.accessor]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </tbody>
        </StyledTable>
      </ScrollContainer>
    </TableWrapper>
  )
}

export default Table
